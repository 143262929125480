<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="maintenance-team-detail-modal"
                title="运维班组详情"
                :visible.sync="dialogVisible"
                width="600px"
            >
                <div class="team-detail-container">
                    <div class="detail-item">
                        <div class="item-lable">
                            班组名称：
                        </div>
                        <div class="item-value">
                            {{data.gangerGroupName}}
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="item-lable">
                            班组状态
                        </div>
                        <div class="item-value">
                            {{formatStatus(data.status)}}
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="item-lable">
                            归属机构：
                        </div>
                        <div class="item-value">
                            {{data.orgName}}
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="item-lable">
                            归属站点：
                        </div>
                        <div class="item-value">
                            {{data.siteName}}
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="item-lable">
                            班组长：
                        </div>
                        <div class="item-value">
                            {{data.operationHeadmanName}}
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="item-lable">
                            班组人数：
                        </div>
                        <div class="item-value">
                            {{data.operationPersonNum}}
                        </div>
                    </div>
                </div>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './DetailModal.less';
export default {
    name: 'DetailModal',
    props: {
        data: {
            type: Object,
            default: () => ({
                gangerGroupName: '',
                status: '',
                orgName: '',
                siteName: '',
                operationHeadmanName: '',
                operationPersonNum: ''
            })
        }
    },
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        clickHandel() {
            this.dialogVisible = true;
        },
        formatStatus(status) {
            return status === 1 ? '启用' : '停用';
        }
    }
}
</script>