<template>
    <div class="maintenance-team-management-container">
        <div class="table-tool">
            <TableTool @queryChange="queryChangeHandel"/>
        </div>
        <div class="table-container">
            <div class="table-data-info">
                <img class="tips-icon" :src="require('@/assets/img/volume.png')"/>
                <span>运维班组总数</span><span style="margin: 0 10px; color: #FF0000;">{{tableData.total}},</span>
                <span>本机构运维班组</span><span style="margin: 0 10px; color: #FF0000;">{{tableData.total}}</span>
            </div>
            <div class="table-content">
                <el-table 
                    style="width: 100%"
                    height="100%"
                    :data="tableData.records"
                >
                    <el-table-column
                        prop="id"
                        label="班组ID"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="gangerGroupName"
                        label="班组名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="siteName"
                        label="归属站点"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="orgName"
                        label="归属机构"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="状态"
                    >
                        <template slot-scope="scope">
                            {{formatStatus(scope.row.status)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="创建时间"
                        width="180px"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="operationHeadmanName"
                        label="班组长"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="operationPersonNum"
                        label="运维人员数量"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="200"
                    >
                        <template slot-scope="scope">
                            <DetailModal :data="scope.row">
                                <a class="operate-a">查看</a>
                            </DetailModal>
                            <OperateModal :operateType="'edit'" :fillData="scope.row" :queryChangeHandel="getTableData">
                                <a class="operate-a">编辑</a>
                            </OperateModal>
                            <a class="operate-a" @click="deleteHandel(scope.row.id)">删除</a>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-page">
                <div class="table-page-container">
                    <el-pagination
                        background
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableTool from './components/TableTool';
import './Index.less';
import OperateModal from './components/OperateModal';
import DetailModal from './components/DetailModal';
import axios from '@/utils/axios';
export default {
    name: 'MaintenanceTeamManagement',
    components: {
        TableTool,
        OperateModal,
        DetailModal
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            queryParams: {}
        }
    },
    computed: {
        tableData() {
            return this.$store.state.maintenanceTeamManagement.data;
        }
    },
    methods: {
        queryChangeHandel(params) {
            this.queryParams = params;
            this.page = 1;
            this.getTableData();
        },
        getTableData() {
            const { page, pageSize, queryParams } = this; 
            this.$store.dispatch({
                type: 'maintenanceTeamManagement/fetchData',
                payload: {
                    params: queryParams,
                    page,
                    pageSize
                }
            });
        },
        deleteHandel(id) {
            this.$confirm('确认删除此班组, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post(`/api/gangerGroup/remove/${id}`).then(
                    () => {
                        this.$message.success('删除成功');
                        this.getTableData();
                    }
                ).catch(err => {
                    this.$message.error(err);
                });
            });
        },
        formatStatus(status) {
            return status === 1 ? '启用' : '停用';
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getTableData();
        },
    },
    mounted() {
        this.getTableData();
    }
}
</script>