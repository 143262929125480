<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="maintenance-team-operate-modal"
                :title="operateType === 'add' ? '新增班组' : '编辑班组'"
                :visible.sync="dialogVisible"
                width="600px"
            >
                <div class="team-form-container">
                    <el-form 
                        :model="formData" 
                        :rules="rules" 
                        ref="operateForm" 
                        label-width="100px" 
                        class="operate-form"
                    >
                        <el-form-item label="班组名称" prop="gangerGroupName">
                            <el-input v-model="formData.gangerGroupName"></el-input>
                        </el-form-item>
                        <el-form-item label="归属站点" prop="siteId">
                            <el-tree-select 
                                v-model="formData.siteId"
                                ref="group-select-tree"
                                class="group-select-tree" 
                                :selectParams="selectParams" 
                                :treeParams="treeParams" 
                                style="width: 220px;"
                            >
                            </el-tree-select>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-radio-group v-model="formData.status">
                                <el-radio :label="1">启用</el-radio>
                                <el-radio :label="2">停用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="operateType === 'edit'" label="班组长" prop="operationHeadmanId">
                            <el-select v-model="formData.operationHeadmanId" placeholder="请选择">
                                <el-option
                                    v-for="item in headManList"
                                    :key="item.key"
                                    :label="item.value"
                                    :value="item.key">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false;">取 消</el-button>
                    <el-button type="primary" @click="sureHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './OperateModal.less';
import axios from '@/utils/axios';
export default {
    name: 'OperateModal',
    props: {
        operateType: {
            type: String,
            default: 'add'
        },
        queryChangeHandel: {
            type: Function,
            default: () => {}
        },
        fillData: {
            type: Object,
            default: () => ({})
        }
    },
    data: function () {
        return {
            dialogVisible: false,
            formData: {
                gangerGroupName: '',
                siteId: '',
                status: 1,
                operationHeadmanId: ''
            },
            rules: {
                gangerGroupName: [
                    { required: true, message: '请输入班组名称', trigger: 'blur' },
                ],
                siteId: [
                    { required: true, message: '请选择所属站点', trigger: 'blur' },
                ],
                operationHeadmanId: [
                    { required: false, message: '请选择班组长', trigger: 'blur' },
                ]
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'label',
                    value: 'id'
                }
            },
            headManList: []
        }
    },
    methods: {
        clickHandel() {
            this.dialogVisible = true;
            if (this.operateType === 'edit') {
                this.getHeadManList(this.fillData.id);
                this.fillData.siteId =  `site-${this.fillData.siteId}`;
                this.formData = this.fillData;
            }
            this.$nextTick(() => {
                this.getSiteList();
            });
        },
        getSiteList() {
            const currentUserStr = localStorage.getItem('currentUser');
            const { orgId } = JSON.parse(currentUserStr);
            axios.get(`/api/org/getOrgTree/${orgId}`).then(rs => {
                this.recursionTreeData([rs]);
                this.treeParams.data = [rs];
                this.$refs['group-select-tree'].treeDataUpdateFun(this.treeParams.data);
            }).catch(err => {
                this.$message.error(err);
            });
        },
        sureHandel() {
            const {
                gangerGroupName,
                status,
                operationHeadmanId,
                siteId
            } = this.formData;
            if (!(siteId.toString().indexOf('-') > 0)) {
                this.$message.info('请选择站点');
                return;
            }
            let _siteId = siteId.split('-')[1];
            this.$refs['operateForm'].validate(valid => { 
                if (valid) {
                    if (this.operateType === 'add') {
                        axios.post('/api/gangerGroup/create', {
                            gangerGroupName,
                            status,
                            operationHeadmanId,
                            siteId: Number(_siteId)
                        }).then(() => {
                            this.$refs['operateForm'].resetFields();
                            this.dialogVisible = false;
                            this.queryChangeHandel();
                        }).catch(err => {
                            this.$message.error(err);
                        });
                    } else {
                        axios.post('/api/gangerGroup/edit', {
                            gangerGroupName,
                            status,
                            operationHeadmanId,
                            siteId: Number(_siteId),
                            id: this.fillData.id
                        }).then(() => {
                            this.$refs['operateForm'].resetFields();
                            this.dialogVisible = false;
                            this.queryChangeHandel();
                        }).catch(err => {
                            this.$message.error(err);
                        });
                    }
                }
            });
        },
        recursionTreeData(data) {
            data.forEach(element => {
                if (element.orgName) {
                    element.label = element.orgName;
                    element.type = 'org';
                }
                if (element.sites && element.sites.length > 0) {
                    const sites = element.sites.map(item => ({ id: `site-${item.id}`, label: item.siteName + '(站点)', type: 'site' }))
                    element.childs = [...element.childs, ...sites];
                }
                if (element.childs && element.childs.length > 0) {
                    this.recursionTreeData(element.childs)
                }
            });
        },
        getHeadManList(gangerGroupId) {
            axios.get(`/api/operationPerson/getOperationPersonList/${gangerGroupId}`).then(rs => {
                rs.forEach(item => {
                    item.key = Number(item.key);
                });
                this.headManList = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        }
    }
}
</script>