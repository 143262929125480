<template>
    <div class="table-tool-container">
        <div class="table-search-tool-container">
            <el-form class="maintenance-person-form" :inline="true" :model="formData">
                <el-form-item label="班组">
                    <el-input placeholder="班组" size="small" v-model="formData.gangerGroupName"></el-input>
                </el-form-item>
                <el-form-item label="归属">
                    <el-tree-select 
                        v-model="formData.orgId"
                        ref="group-select-tree"
                        class="group-select-tree" 
                        :selectParams="selectParams" 
                        :treeParams="treeParams" 
                        style="width: 220px;"
                    >
                    </el-tree-select>
                </el-form-item>
                <el-form-item class="account-status-form-item" label="状态">
                    <el-select v-model="formData.status" placeholder="状态" size="small">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="启用" :value="1"></el-option>
                        <el-option label="停用" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right; marginRight: 0px;">
                    <el-button type="primary" @click="queryChangeHandel">搜索</el-button>
                    <el-button>重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-operate-tool-container">
            <OperateModal :queryChangeHandel="queryChangeHandel">
                <el-button type="primary">新建</el-button>
            </OperateModal>
        </div>
    </div>
</template>

<script>
import './TableTool.less';
import axios from '@/utils/axios';
import OperateModal from './OperateModal';
export default {
    name: 'TableTool',
    components: {
        OperateModal
    },
    data() {
        return {
            formData: {
                gangerGroupName: '',
                orgId: '',
                status: ''
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgId'
                }
            }
        }
    },
    methods: {
        async getGroupTreeData() {
            const rs = await axios.get('/api/userInfo/getCurrLoginUserInfo');
            const { orgId } = rs.org;
            const groupTreeData = await axios.get(`/api/org/getOrgTree/${orgId}`);
            this.treeParams.data = [groupTreeData];
            this.$refs['group-select-tree'].treeDataUpdateFun([groupTreeData]);
        },
        queryChangeHandel() {
            this.$emit('queryChange', this.formData);
        }
    },
    mounted() {
        this.getGroupTreeData();
    }
}
</script>